/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { urlConfig } from 'config/url.config'
import { useLogin } from 'hooks/use-login'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { cn } from 'utils/cn'
import { z } from 'zod'

const passwordTypes = {
    password: 'password',
    text: 'text',
}

const loginSchema = z.object({
    email: z
        .string()
        .email({
            message: 'Wrong email address',
        })
        .min(5),
    password: z.string().min(5, {
        message: 'Please enter correct password!',
    }),
})

const Login = () => {
    const { theme } = useAppSelector(state => state.theme)
    const [passwordType, setPasswordType] = useState<string>(passwordTypes.password)
    const { isButtonDisabled, loginWithEmailAndPassword } = useLogin()

    const navigate = useNavigate()

    const loginForm = useForm<z.infer<typeof loginSchema>>({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            email: '',
            password: '',
        },
    })

    function onSubmit(values: z.infer<typeof loginSchema>) {
        function navigateTo() {
            navigate(urlConfig.pages.dashboard)
        }

        loginWithEmailAndPassword(values.email, values.password, navigateTo)
    }

    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center">
            <div
                className={cn(
                    'flex flex-col gap-3 rounded-xl border-4 p-5 lg:w-1/3',
                    theme === 'dark' ? 'border-[#0B1E55] bg-[#030E31]' : 'bg-slate-200',
                )}
            >
                <span className="text-4xl font-semibold">Login</span>
                <Form {...loginForm}>
                    <form onSubmit={loginForm.handleSubmit(onSubmit)} className="flex w-full flex-col gap-5">
                        <FormField
                            control={loginForm.control}
                            name="email"
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className="text-lg">Email</FormLabel>
                                    <FormControl>
                                        <Input className="" placeholder="email@doe.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={loginForm.control}
                            name="password"
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className="flex justify-between text-lg">
                                        <span>Password</span>
                                        <span
                                            className="cursor-pointer text-green-500"
                                            onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}
                                        >
                                            Show
                                        </span>
                                    </FormLabel>
                                    <FormControl>
                                        <Input id="password" className="" placeholder="*****" type={passwordType} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Link to="/reset" className="w-fit cursor-pointer text-lg text-green-500">
                            Forgot password?
                        </Link>
                        <Button disabled={isButtonDisabled} type="submit" className="rounded-xl py-7 text-xl uppercase ">
                            <LoadingIcon loading={isButtonDisabled} className="mr-2" />
                            Login
                        </Button>
                        <div className="flex justify-center gap-1 text-xl">
                            <span>Don’t an account?</span>
                            <Link to="/register" className="cursor-pointer text-green-500">
                                Sign up
                            </Link>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    )
}
export default Login
