import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ThemeType } from 'types/theme.type'

interface ThemeSliceState {
    theme: ThemeType
}

const initialState: ThemeSliceState = {
    theme: (() => {
        const theme = localStorage.getItem('theme')
        if (theme === 'light') return 'light'
        if (theme === 'dark') return 'dark'
        if (theme === 'system') return 'system'
        return 'dark'
    })(),
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setThemeAction: (state, action: PayloadAction<ThemeSliceState['theme']>) => {
            state.theme = action.payload
            localStorage.setItem('theme', action.payload)
        },
    },
})

export const { setThemeAction } = themeSlice.actions
