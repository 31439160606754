import { ColumnDef } from '@tanstack/react-table'
import { TableUrlCell } from 'components/table-url-cell'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { urlConfig } from 'config/url.config'
import dayjs from 'dayjs'
import { DashboardIntervalCell } from 'pages/dashboard/cells/dashboard-interval-cell'
import { DashboardRowActionsCell } from 'pages/dashboard/cells/dashboard-row-actions-cell'
import { DashboardStatusCell } from 'pages/dashboard/cells/dashboard-status-cell'
import { ProjectSchemaType } from 'types/schemas/project.schema'

export const dashboardColumns: ColumnDef<ProjectSchemaType>[] = [
    {
        accessorKey: 'id',
        header: ({ column }) => <DataTableColumnHeader column={column} title="ID" />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => {
            return (
                <div
                    onClick={() =>
                        row.original.navigateFn?.(`${urlConfig.pages.projectDetails.replace(':id', row.getValue('id'))}`)
                    }
                    className="w-full cursor-pointer"
                >
                    {row.getValue('name')}
                </div>
            )
        },
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'url',
        header: ({ column }) => <DataTableColumnHeader column={column} title="URL" />,
        cell: ({ row }) => <TableUrlCell url={row.getValue('url')} />,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'email',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
        cell: ({ row }) => {
            return (
                <div
                    onClick={() =>
                        row.original.navigateFn?.(`${urlConfig.pages.projectDetails.replace(':id', row.getValue('id'))}`)
                    }
                    className="w-full cursor-pointer"
                >
                    {row.getValue('email')}
                </div>
            )
        },
        sortingFn: 'text',
        filterFn: 'includesString',
    },

    {
        accessorKey: 'statusCode',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Code" />,
        cell: ({ row }) => {
            return (
                <div
                    onClick={() =>
                        row.original.navigateFn?.(`${urlConfig.pages.projectDetails.replace(':id', row.getValue('id'))}`)
                    }
                    className="w-full cursor-pointer"
                >
                    {row.getValue('statusCode')}
                </div>
            )
        },
        sortingFn: 'text',
    },
    {
        accessorKey: 'ping',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Ping" />,
        cell: ({ row }) => {
            return (
                <div
                    onClick={() =>
                        row.original.navigateFn?.(`${urlConfig.pages.projectDetails.replace(':id', row.getValue('id'))}`)
                    }
                    className="w-full cursor-pointer"
                >
                    {row.getValue('ping')}
                </div>
            )
        },
        sortingFn: 'text',
    },
    {
        accessorKey: 'interval',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Interval" />,
        cell: ({ row }) => {
            return (
                <div
                    onClick={() =>
                        row.original.navigateFn?.(`${urlConfig.pages.projectDetails.replace(':id', row.getValue('id'))}`)
                    }
                    className="w-full cursor-pointer"
                >
                    <DashboardIntervalCell interval={row.getValue('interval')} />
                </div>
            )
        },
        sortingFn: 'text',
    },
    {
        accessorKey: 'lastCheck',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Last Update" />,
        cell: ({ row }) => {
            return (
                <div
                    onClick={() =>
                        row.original.navigateFn?.(`${urlConfig.pages.projectDetails.replace(':id', row.getValue('id'))}`)
                    }
                    className="w-full cursor-pointer"
                >
                    {dayjs(row.getValue('lastCheck')).format('DD MMM YYYY HH:mm:ss')}
                </div>
            )
        },
        sortingFn: 'datetime',
    },
    {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row }) => <DashboardStatusCell id={row.getValue('id')} status={row.getValue('status')} />,
        sortingFn: 'text',
        filterFn: 'weakEquals',
    },
    {
        accessorKey: 'actions',
        header: 'Actions',
        cell: ({ row }) => <DashboardRowActionsCell id={row.getValue('id')} name={row.getValue('name')} />,
    },
]
