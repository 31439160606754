import { FullPageLoader } from 'components/full-page-loader'
import { Button } from 'components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card'
import { Separator } from 'components/ui/separator'
import { urlConfig } from 'config/url.config'
import dayjs from 'dayjs'
import { ChevronLeft, Loader, SquareArrowOutUpRight, TrendingDown, TrendingUp } from 'lucide-react'
import { AdminActionsCell } from 'pages/admin/cells/admin-actions'
import { UpdateActionsCell } from 'pages/admin/cells/updateproject-action'
import { CreateProjectSheet } from 'pages/create-project/create-project-sheet'
import { memo, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useGetProjectByIdQuery } from 'redux/api/project-api'
import { useGetProjectLogsByProjectIdQuery } from 'redux/api/project-log-api'
import { ProjectLogType } from 'types/project-log.type'
import { ProjectStatus } from 'types/schemas/project.schema'
import { cn } from 'utils/cn'

const ProjectDetails = memo(() => {
    const { id } = useParams()

    const navigate = useNavigate()

    if (!id) navigate(urlConfig.pages.dashboard)

    const [incidentsLast24Hours, setIncidentsLast24Hours] = useState<number>(0)
    const [incidentsLast7Days, setIncidentsLast7Days] = useState<number>(0)
    const [incidentsLast30Days, setIncidentsLast30Days] = useState<number>(0)
    const [incidentsLast365Days, setIncidentsLast365Days] = useState<number>(0)

    const { data: project, isLoading: isLoadingProject, refetch: refetchProject } = useGetProjectByIdQuery(id as string)
    console.log(project)
    const { data: projectLogs, isLoading: isLoadingLogs, refetch: refetchLogs } = useGetProjectLogsByProjectIdQuery(id as string)

    const statusPercents = useMemo(() => {
        if (project?.status === ProjectStatus.OK) return '100%'
        if (project?.status === ProjectStatus.DOWN) return '0%'
        if (project?.status === ProjectStatus.REDIRECT) return '0%'
        return '50%'
    }, [project?.status])

    useEffect(() => {
        if (projectLogs) {
            const now = dayjs()
            const last24Hours = now.subtract(1, 'day')
            const last7Days = now.subtract(7, 'day')
            const last30Days = now.subtract(30, 'day')
            const last365Days = now.subtract(365, 'day')

            const incidents24H = projectLogs.filter((log: ProjectLogType) => dayjs(log.date).isAfter(last24Hours))
            const incidents7D = projectLogs.filter((log: ProjectLogType) => dayjs(log.date).isAfter(last7Days))
            const incidents30D = projectLogs.filter((log: ProjectLogType) => dayjs(log.date).isAfter(last30Days))
            const incidents365D = projectLogs.filter((log: ProjectLogType) => dayjs(log.date).isAfter(last365Days))

            setIncidentsLast24Hours(incidents24H.length)
            setIncidentsLast7Days(incidents7D.length)
            setIncidentsLast30Days(incidents30D.length)
            setIncidentsLast365Days(incidents365D.length)
        }
    }, [projectLogs])

    console.log(incidentsLast24Hours, incidentsLast7Days, incidentsLast30Days, incidentsLast365Days)

    if (isLoadingProject || isLoadingLogs) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 container min-h-screen space-y-5 pt-20  transition-all duration-300">
            <div>
                <Button variant="outline" className="flex items-center gap-1" onClick={() => navigate(urlConfig.pages.dashboard)}>
                    <ChevronLeft />
                    Back
                </Button>
            </div>
            <div className="flex items-center gap-3">
                <div
                    className={cn(
                        'size-10 rounded-full border-2',
                        'flex items-center justify-center',
                        project?.status === ProjectStatus.OK && 'border-green-600 bg-green-300 text-green-700',
                        (project?.status === ProjectStatus.DOWN || project?.status === ProjectStatus.REDIRECT) &&
                            'border-red-600 bg-red-300 text-red-700',
                        project?.status === ProjectStatus.PENDING && 'border-yellow-600 bg-yellow-300 text-yellow-700',
                    )}
                >
                    {project?.status === ProjectStatus.OK ? (
                        <TrendingUp />
                    ) : project?.status === ProjectStatus.DOWN ? (
                        <TrendingDown />
                    ) : (
                        <Loader />
                    )}
                </div>
                <div className="flex flex-row items-start">
                    <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                            <p className="text-3xl font-semibold">{project?.name}</p>
                            <Link target="_blank" to={project?.url as string}>
                                <SquareArrowOutUpRight />
                            </Link>
                        </div>
                        <div className="flex items-center gap-1">
                            <p className="text-muted-foreground">HTTP(S) monitor for </p>
                            <Link target="_blank" to={project?.url as string} className="text-green-600 underline">
                                {project?.url}
                            </Link>
                        </div>
                    </div>

                    <UpdateActionsCell
                        id={project?.id as string}
                        name={project?.name as string}
                        url={project?.url as string}
                        email={project?.email as string}
                        interval={project?.interval as number}
                    />
                </div>
            </div>
            <div className="flex gap-5">
                <Card className="flex w-fit min-w-[200px] flex-col gap-3 px-8 py-5">
                    <CardHeader className="p-0">
                        <CardTitle className="text-muted-foreground text-base">Current status</CardTitle>
                    </CardHeader>
                    <CardContent className=" flex items-center justify-between p-0">
                        <span
                            className={cn(
                                'text-center text-2xl font-semibold uppercase',
                                project?.status === ProjectStatus.OK && ' text-green-700',
                                (project?.status === ProjectStatus.DOWN || project?.status === ProjectStatus.REDIRECT) &&
                                    ' text-red-700',
                                project?.status === ProjectStatus.PENDING && ' text-yellow-500',
                            )}
                        >
                            {project?.status}
                        </span>
                    </CardContent>
                </Card>

                <Card className="flex w-fit flex-col gap-3 px-6 py-5">
                    <CardHeader className="p-0">
                        <CardTitle className="text-muted-foreground text-base">Last check</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-col items-start justify-between p-0">
                        <span className={cn('text-center text-2xl font-semibold')}>
                            {dayjs(project?.lastCheck).format('DD MMM YYYY HH:mm:ss')}
                        </span>
                        <span className={cn('text-muted-foreground text-center text-base')}>
                            Checked every {project?.interval} minutes
                        </span>
                    </CardContent>
                </Card>

                <Card className="flex w-64 flex-col gap-3 px-6 py-5">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 p-0">
                        <CardTitle className="text-muted-foreground m-0 p-0 text-base">Last 24 hours</CardTitle>
                        <CardTitle className="text-muted-foreground m-0 p-0 text-base">{statusPercents}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-col items-start justify-between p-0">
                        <div className="mb-3 flex w-full items-center justify-between gap-[0.5px]">
                            {Array.from({ length: 25 }).map((_, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={cn(
                                            'uppercase',
                                            'h-5 w-1 rounded',
                                            project?.status === ProjectStatus.OK && 'bg-green-500',
                                            (project?.status === ProjectStatus.DOWN ||
                                                project?.status === ProjectStatus.REDIRECT) &&
                                                'bg-red-500',
                                            project?.status === ProjectStatus.PENDING && 'bg-yellow-500',
                                        )}
                                    ></div>
                                )
                            })}
                        </div>
                        <span className={cn('text-muted-foreground text-center text-base')}>
                            {incidentsLast24Hours} down incidents
                        </span>
                    </CardContent>
                </Card>
            </div>
            <div className="flex gap-5">
                <Card className="flex w-fit flex-col gap-3 px-6 py-5">
                    <CardContent className="flex items-center justify-between gap-5 p-0">
                        <div className="flex flex-col gap-2">
                            <span className={cn('text-muted-foreground text-base')}>Last 7 days</span>

                            {incidentsLast7Days === 0 ? (
                                <span className="text-2xl font-bold text-green-500">{100 - incidentsLast30Days / 100}%</span>
                            ) : (
                                <span className="text-2xl font-bold text-red-500">{100 - incidentsLast30Days / 100}%</span>
                            )}
                            {/* <span
                                className={cn(
                                    'text-2xl font-semibold uppercase',
                                    project?.status === ProjectStatus.OK && ' text-green-700',
                                    (project?.status === ProjectStatus.DOWN || project?.status === ProjectStatus.REDIRECT) &&
                                        ' text-red-700',
                                    project?.status === ProjectStatus.PENDING && ' text-yellow-500',
                                )}
                            >
                                {project?.status}
                            </span> */}
                            <span className={cn('text-muted-foreground text-center text-base')}>
                                {incidentsLast7Days} down incidents
                            </span>
                        </div>
                        <Separator orientation="vertical" />
                        <div className="flex flex-col gap-2">
                            <span className={cn('text-muted-foreground text-base')}>Last 30 days</span>
                            {incidentsLast7Days === 0 ? (
                                <span className="text-2xl font-bold text-green-500">{100 - incidentsLast30Days / 100}%</span>
                            ) : (
                                <span className="text-2xl font-bold text-red-500">{100 - incidentsLast30Days / 100}%</span>
                            )}
                            {/* <span
                                className={cn(
                                    'text-2xl font-semibold uppercase',
                                    project?.status === ProjectStatus.OK && ' text-green-700',
                                    (project?.status === ProjectStatus.DOWN || project?.status === ProjectStatus.REDIRECT) &&
                                        ' text-red-700',
                                    project?.status === ProjectStatus.PENDING && ' text-yellow-500',
                                )}
                            >
                                {project?.status}
                            </span> */}
                            <span className={cn('text-muted-foreground text-center text-base')}>
                                {incidentsLast30Days} down incidents
                            </span>
                        </div>
                        <Separator orientation="vertical" />
                        <div className="flex flex-col gap-2">
                            <span className={cn('text-muted-foreground text-base')}>Last 365 days</span>
                            {incidentsLast365Days === 0 ? (
                                <span className="text-2xl font-bold text-green-500">{100 - incidentsLast30Days / 100}%</span>
                            ) : (
                                <span className="text-2xl font-bold text-red-500">{100 - incidentsLast30Days / 100}%</span>
                            )}
                            {/* <span
                                className={cn(
                                    'text-2xl font-semibold uppercase',
                                    project?.status === ProjectStatus.OK && ' text-green-700',
                                    (project?.status === ProjectStatus.DOWN || project?.status === ProjectStatus.REDIRECT) &&
                                        ' text-red-700',
                                    project?.status === ProjectStatus.PENDING && ' text-yellow-500',
                                )}
                            >
                                {project?.status}
                            </span> */}
                            <span className={cn('text-muted-foreground text-center text-base')}>
                                {incidentsLast365Days} down incidents
                            </span>
                        </div>

                        <Separator orientation="vertical" />
                        <div className="flex flex-col gap-2">
                            <span className={cn('text-muted-foreground text-base')}>
                                <select className="rounded-md border bg-gray-400/10">
                                    <option value="">Select...</option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                </select>
                            </span>
                            {incidentsLast365Days === 0 ? (
                                <span className="text-2xl font-bold text-green-500">{100 - incidentsLast30Days / 100}%</span>
                            ) : (
                                <span className="text-2xl font-bold text-red-500">{100 - incidentsLast30Days / 100}%</span>
                            )}
                            {/* <span
                                className={cn(
                                    'text-2xl font-semibold uppercase',
                                    project?.status === ProjectStatus.OK && ' text-green-700',
                                    (project?.status === ProjectStatus.DOWN || project?.status === ProjectStatus.REDIRECT) &&
                                        ' text-red-700',
                                    project?.status === ProjectStatus.PENDING && ' text-yellow-500',
                                )}
                            >
                                {project?.status}
                            </span> */}
                            <span className={cn('text-muted-foreground text-center text-base')}>
                                {incidentsLast365Days} incidents, -down
                            </span>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
})
export default ProjectDetails
