import { OnSignInWithGoogle } from 'components/sign-in-with-google'
import { ENV, dotenv } from 'config/dotenv.config'
import { firebaseAuth } from 'config/firebase.config'
import { toastConfig } from 'config/toasts.config'
import { urlConfig } from 'config/url.config'
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { useCallback, useState } from 'react'
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import { UserRole } from 'types/schemas/user.schema'

type AuthState = Parameters<OnSignInWithGoogle>[0]

export const useLogin = () => {
    const signIn = useSignIn()
    const navigate = useNavigate()
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false)

    const login = useCallback<(params: { authState: AuthState; callbackUrl: string }) => Promise<void>>(
        async ({ authState, callbackUrl }) => {
            try {
                if (!authState.email || !authState.name) {
                    throw new Error('Invalid auth state')
                }

                setIsButtonDisabled(true)

                const resPromise = await fetch(`${dotenv.get(ENV.API_URL)}${urlConfig.api.login}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: authState.email,
                    }),
                })
                if (!resPromise.ok) {
                    throw new Error('Failed to login')
                }

                const res = (await resPromise.json()) as {
                    message: string
                    token: string
                    role: UserRole
                    id: string
                    userOwnerId: string | undefined
                }
                if (!res.token) {
                    throw new Error('Failed to login')
                }

                signIn({
                    token: res.token,
                    expiresIn: 24 * 60,
                    tokenType: 'Bearer',
                    authState: {
                        ...authState,
                        role: res.role,
                        id: res.id,
                        userOwnerId: res.userOwnerId === 'owner' ? res.id : res.userOwnerId,
                    },
                })
                navigate(callbackUrl)
            } catch (error) {
                console.error(error)
            } finally {
                setIsButtonDisabled(false)
            }
        },
        [navigate, signIn],
    )

    const loginWithEmailAndPassword = useCallback<
        (email: string, password: string, cb?: () => void | Promise<void>) => Promise<void>
    >(
        async (email, password, cb) => {
            try {
                setIsButtonDisabled(true)
                await signInWithEmailAndPassword(firebaseAuth, email, password)
                    .then(async userCredential => {
                        const user = userCredential.user
                        await login({
                            authState: {
                                name: user.displayName ?? undefined,
                                email: user.email ?? undefined,
                            },
                            callbackUrl: urlConfig.pages.dashboard,
                        }).then(() => {
                            toastConfig.login.success(user.displayName ?? 'Unknown user')
                            setIsButtonDisabled(false)
                        })
                    })
                    .catch(error => {
                        toastConfig.login.error()
                        setIsButtonDisabled(false)
                    })
                cb?.()
            } catch (error) {
                toastConfig.login.error()
                setIsButtonDisabled(false)
            }
        },
        [login],
    )

    const registerWithEmailAndPassword = useCallback<
        (email: string, password: string, name: string, cb?: () => void | Promise<void>) => Promise<void>
    >(async (email, password, name, cb) => {
        try {
            setIsButtonDisabled(true)
            await createUserWithEmailAndPassword(firebaseAuth, email, password)
                .then(async userCredential => {
                    const user = userCredential.user

                const resPromise = await fetch(`${dotenv.get(ENV.API_URL)}${urlConfig.api.newUserReg}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userEmail: email,
                    }),
                })
                if (!resPromise.ok) {
                    throw new Error('Response error')
                }

                    updateProfile(user, { displayName: name })
                        .then()
                        .catch(error => {
                            console.error('Error updating profile:', error.message)
                        })
                })
                .catch(error => {
                    toastConfig.register.error()
                    setIsButtonDisabled(false)
                })
            cb?.()
        } catch (error) {
            toastConfig.register.error()
            setIsButtonDisabled(false)
        }
    }, [])

    const resetUserPassword = useCallback<(email: string, cb?: () => void | Promise<void>) => Promise<void>>(
        async (email, cb) => {
            try {
                setIsButtonDisabled(true)
                await sendPasswordResetEmail(firebaseAuth, email)
                    .then(res => {
                        cb?.()
                        toastConfig.reset.resetWithEmail(email)
                        setIsButtonDisabled(false)
                    })
                    .catch(error => {
                        const errorCode = error.code
                        const errorMessage = error.message
                        toastConfig.reset.error()
                        setIsButtonDisabled(false)
                    })
            } catch (error) {
                toastConfig.reset.error()
                setIsButtonDisabled(false)
            }
        },
        [],
    )

    return {
        login,
        isButtonDisabled,
        loginWithEmailAndPassword,
        registerWithEmailAndPassword,
        resetUserPassword,
    } as const
}
