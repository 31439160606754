export const urlConfig = {
    pages: {
        dashboard: '/',
        'all-login': '/all-login',
        login: '/login',
        register: '/register',
        reset: '/reset',
        projectsLogs: '/projects-logs',
        appLogs: '/app-logs',
        admin: '/admin',
        projectDetails: '/project/:id',
    },
    api: {
        login: '/login',
        counter: '/counter',
        project: '/project',
        revalidate: '/revalidate',
        projectLogs: '/project-log',
        appLogs: '/app-log',
        newUserReg: '/login/new-user-registration',
    },
}
