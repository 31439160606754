import { CommandMenu } from 'components/command-menu'
import { Providers } from 'components/hoc/providers'
import { RequireAuth } from 'components/hoc/require-auth'
import { ThemeProvider } from 'components/hoc/theme-provider'
import { ThrowAuth } from 'components/hoc/throw-auth'
import { Navbar } from 'components/navbar'
import { TailwindIndicator } from 'components/tailwind-indicator'
import { Toaster } from 'components/ui/toaster'
import { urlConfig } from 'config/url.config'
import Admin from 'pages/admin/admin'
import { AppLogsPage } from 'pages/app-logs/app-logs-page'
import { MainPage } from 'pages/dashboard/dashboard-page'
import { ErrorPage } from 'pages/error-page'
import Login from 'pages/login/login'
import { LoginPage } from 'pages/login/login-page'
import Register from 'pages/login/register'
import ResetPassword from 'pages/login/reset-password'
import { NotFoundPage } from 'pages/not-found-page'
import ProjectDetails from 'pages/project-details/project-details'
import { ProjectLogsPage } from 'pages/project-logs/project-logs-page'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { UserRole } from 'types/schemas/user.schema'

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorPage}>
                <Providers>
                    <Navbar />
                    <Routes>
                        <Route
                            path={urlConfig.pages.dashboard}
                            element={
                                <RequireAuth>
                                    <MainPage />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages.projectDetails}
                            element={
                                <RequireAuth>
                                    <ProjectDetails />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages.projectsLogs}
                            element={
                                <RequireAuth>
                                    <ProjectLogsPage />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages.appLogs}
                            element={
                                <RequireAuth>
                                    <AppLogsPage />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages.admin}
                            element={
                                <RequireAuth allowedRoles={[UserRole.SUPERADMIN, UserRole.ADMIN]}>
                                    <Admin />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages['all-login']}
                            element={
                                <ThrowAuth>
                                    <LoginPage />
                                </ThrowAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages.login}
                            element={
                                <ThrowAuth>
                                    <Login />
                                </ThrowAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages.register}
                            element={
                                <ThrowAuth>
                                    <Register />
                                </ThrowAuth>
                            }
                        />
                        <Route
                            path={urlConfig.pages.reset}
                            element={
                                <ThrowAuth>
                                    <ResetPassword />
                                </ThrowAuth>
                            }
                        />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                    <Toaster />
                    <CommandMenu />
                    {/*  */}
                    <ThemeProvider />
                    <TailwindIndicator />
                </Providers>
            </ErrorBoundary>
        </BrowserRouter>
    )
}

export default App
