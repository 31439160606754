import { Button } from 'components/ui/button'
import { memo } from 'react'
import { Link } from 'react-router-dom'
import { cn } from 'utils/cn'

interface TableUrlCellProps extends React.ComponentProps<typeof Button> {
    url: string
    linkProps?: React.ComponentProps<typeof Link>
}
export const TableUrlCell: React.FC<TableUrlCellProps> = memo(({ url, className, linkProps, ...props }) => {
    return (
        <Button variant="link" asChild {...props} className={cn('p-0 font-normal', className)}>
            <Link to={url} target="_blank" {...linkProps}>
                {url}
            </Link>
        </Button>
    )
})
TableUrlCell.displayName = 'TableUrlCell'
