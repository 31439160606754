import { ENV, dotenv } from 'config/dotenv.config'
import { toastConfig } from 'config/toasts.config'
import { urlConfig } from 'config/url.config'
import { memo, useCallback, useMemo } from 'react'
import { useGetProjectsQuery } from 'redux/api/project-api'
import { ProjectSchemaType, ProjectStatus } from 'types/schemas/project.schema'
import { cn } from 'utils/cn'

interface DashboardStatusCellProps extends React.HTMLAttributes<HTMLDivElement> {
    id: ProjectSchemaType['id']
    status: ProjectSchemaType['status']
}
export const DashboardStatusCell: React.FC<DashboardStatusCellProps> = memo(({ id, status, className, ...props }) => {
    const { refetch } = useGetProjectsQuery()

    const buttonVariant = useMemo(() => {
        if (status === ProjectStatus.OK) return 'success'
        if (status === ProjectStatus.DOWN) return 'destructive'
        if (status === ProjectStatus.REDIRECT) return 'destructive'
        return 'ghost'
    }, [status])

    const statusPercents = useMemo(() => {
        if (status === ProjectStatus.OK) return '100%'
        if (status === ProjectStatus.DOWN) return '0%'
        if (status === ProjectStatus.REDIRECT) return '0%'
        return '50%'
    }, [status])

    const onClick = useCallback(async () => {
        try {
            const res = await fetch(`${dotenv.get(ENV.API_URL)}${urlConfig.api.revalidate}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    projectId: id,
                }),
            })
            if (!res.ok) {
                throw new Error('Failed to revalidate')
            }
            refetch()
            toastConfig.refetch.success()
        } catch (error) {
            console.error(error)
            toastConfig.refetch.error()
        }
    }, [id, refetch])

    return (
        <div className="flex cursor-pointer flex-col items-end gap-1" onClick={onClick} {...props}>
            <div className="flex w-full items-center justify-between gap-[0.5px]">
                {Array.from({ length: 15 }).map((_, i) => {
                    return (
                        <div
                            key={i}
                            className={cn(
                                'uppercase',
                                'h-3 w-1 rounded',
                                status === ProjectStatus.OK && 'bg-green-500',
                                (status === ProjectStatus.DOWN || status === ProjectStatus.REDIRECT) && 'bg-red-500',
                                status === ProjectStatus.PENDING && 'bg-yellow-500',
                                className,
                            )}
                        ></div>
                    )
                })}
            </div>
            <span className="text-xs">{statusPercents}</span>
        </div>
    )
})
DashboardStatusCell.displayName = 'DashboardStatusCell'
