import { zodResolver } from '@hookform/resolvers/zod'
import { OnSignInWithGoogle, SignInWithGoogle } from 'components/sign-in-with-google'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { firebaseAuth } from 'config/firebase.config'
import { toastConfig } from 'config/toasts.config'
import { urlConfig } from 'config/url.config'
import { isSignInWithEmailLink, sendSignInLinkToEmail } from 'firebase/auth'
import { useLogin } from 'hooks/use-login'
import { LoginEmailForm } from 'pages/login/components/login-email-form'
import { memo, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useCreateUserMutation, useGetAllUsersQuery } from 'redux/slices/user-api'
import { LoginSchema, LoginSchemaType } from 'types/schemas/login.schema'
import { UserRole } from 'types/schemas/user.schema'
import { NODE_ENV_DEV } from 'utils/NODE_ENV'

const localEmailKey = '_emailForSignIn'

interface LoginPageProps {}
export const LoginPage: React.FC<LoginPageProps> = memo(() => {
    const { login, isButtonDisabled } = useLogin()
    const [createUser, createUserRes] = useCreateUserMutation()
    const { data: allUsers, isLoading } = useGetAllUsersQuery()

    const form = useForm<LoginSchemaType>({
        resolver: zodResolver(LoginSchema),
        defaultValues: {
            email: '',
        },
    })

    const onSignInWithEmail = useCallback<(values: LoginSchemaType) => Promise<void>>(
        async values => {
            try {
                await sendSignInLinkToEmail(firebaseAuth, values.email, {
                    url: window.location.href,
                    handleCodeInApp: true,
                })
                localStorage.setItem(localEmailKey, values.email)
                toastConfig.login.successWithEmail(values.email)
                form.reset()
            } catch (error) {
                toastConfig.login.error()
            }
        },
        [form],
    )
    const onSignInWithGoogle = useCallback<OnSignInWithGoogle>(
        async user => {
            if (user.email && user.name) {
                const existingUser = allUsers?.find(u => u.email === user.email)
                if (!existingUser) {
                    await createUser({
                        email: user.email,
                        name: user.name,
                        role: UserRole.USER,
                        userOwnerId: 'owner',
                    }).unwrap()
                }
            }

            login({
                authState: {
                    name: 'Unknown user',
                    ...user,
                },
                callbackUrl: urlConfig.pages.dashboard,
            }).then(() => {
                toastConfig.login.success(user.name ?? 'Unknown user')
            })
        },
        [allUsers, createUser, login],
    )

    // sign in with google email
    useEffect(() => {
        try {
            if (!isSignInWithEmailLink(firebaseAuth, window.location.href)) {
                return
            }
            const email = window.localStorage.getItem(localEmailKey)
            window.localStorage.removeItem(localEmailKey)
            if (!email) {
                return
            }
            login({
                authState: {
                    email,
                    name: email.split('@')[0],
                },
                callbackUrl: urlConfig.pages.dashboard,
            }).then(() => {
                toastConfig.login.success(email.split('@')[0] ?? 'Unknown user')
            })
        } catch (error) {
            toastConfig.login.error()
        }
    }, [login])

    // visitor activity tracking
    useEffect(() => {
        if (NODE_ENV_DEV) {
            return
        }
        const script = document.createElement('script')
        script.src = 'https://api-zoi76l4r4a-uc.a.run.app/script?projectId=xlsaS9DE2S8DZSr7hyM4'
        script.defer = true
        document.body.appendChild(script)
        return () => {
            document.body.removeChild(script)
        }
    }, [])

    return (
        <div className="animate-in fade-in-0 flex h-full min-h-screen w-full flex-col items-center justify-center gap-5 bg-[url('/src/assets/google-logo-light.svg')] bg-bottom transition-all duration-300 dark:bg-[url('/src/assets/google-logo-dark.svg')]">
            <Card className="w-[400px]">
                <CardHeader>
                    <CardTitle>Sign in to see this page</CardTitle>
                    <CardDescription>You can do it with email or google</CardDescription>
                </CardHeader>
                <CardContent className="flex flex-col gap-5">
                    <LoginEmailForm form={form} onSubmit={onSignInWithEmail} isButtonDisabled={isButtonDisabled} />
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                            <span className="w-full border-t" />
                        </div>
                        <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-card text-muted-foreground px-2">Or continue with</span>
                        </div>
                    </div>
                    <SignInWithGoogle onClick={onSignInWithGoogle} disabled={isButtonDisabled} className="w-full" />
                </CardContent>
            </Card>
        </div>
    )
})
LoginPage.displayName = 'LoginPage'
