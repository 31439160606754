import { zodResolver } from '@hookform/resolvers/zod'
import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card'
import { DataTable } from 'components/ui/data-table'
import { DataTableFacetedFilter } from 'components/ui/data-table-faceted-filter'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { toastConfig } from 'config/toasts.config'
import { useLogin } from 'hooks/use-login'
import { useCallback, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useForm } from 'react-hook-form'
import { useCreateUserMutation, useGetAllAdminUsersQuery } from 'redux/slices/user-api'
import { NewUserSchema, NewUserSchemaType, UserRole } from 'types/schemas/user.schema'
import { adminUsersColumns } from './admin-columns'
import { ManageUsersCreateForm } from './manage-users-create-form'
import { getUserIp } from 'utils/get-user-ip'

const Admin = () => {
    const auth = useAuthUser()()

    const { data, isLoading, refetch, isFetching } = useGetAllAdminUsersQuery(auth?.id)
    console.log(data)
    const [disabled, setDisabled] = useState<boolean>(false)

    const { registerWithEmailAndPassword } = useLogin()
    const [createUser, createUserRes] = useCreateUserMutation()

    const form = useForm<NewUserSchemaType>({
        resolver: zodResolver(NewUserSchema),
        defaultValues: {
            email: '',
            name: '',
            role: UserRole.USER,
        },
    })

    const onSubmit = useCallback<(values: NewUserSchemaType) => Promise<void>>(
        async values => {
            try {
                const ip = await getUserIp()
                setDisabled(true)

                registerWithEmailAndPassword(values.email, values.email, values.name).then(async () => {
                    await createUser({
                        email: values.email,
                        name: values.name,
                        role: values.role,
                        registered_ip: ip,
                        userOwnerId: auth?.userOwnerId,
                    }).unwrap()
                    if (createUserRes.isError) {
                        toastConfig.register.error()
                        throw new Error('Failed to create user')
                    }
                })
                refetch()
                form.reset()
            } catch (error) {
                console.error(error)
                toastConfig.user.create.error()
            } finally {
                setDisabled(false)
            }
        },
        [auth?.userOwnerId, createUser, createUserRes.isError, form, refetch, registerWithEmailAndPassword],
    )

    if (isLoading || isFetching) {
        return <FullPageLoader />
    }
    return (
        <div className="animate-in fade-in-0 container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <Heading title="Manage Users" description="Create, delete, everything is in your hands!" />
            <div className="flex flex-col gap-2 lg:flex-row">
                <Card className="min-w-[280px]">
                    <CardHeader>
                        <CardTitle>Create new User</CardTitle>
                        <CardDescription>Add email, name, select role and that's all!</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <ManageUsersCreateForm
                            form={form}
                            onSubmit={onSubmit}
                            isButtonDisabled={disabled}
                            userRole={auth?.role}
                        />
                    </CardContent>
                </Card>
                <DataTable
                    className="w-full"
                    columns={adminUsersColumns}
                    data={data?.map(user => ({ ...user, refetch: refetch })) ?? []}
                    pagination
                    initialHidden={['id']}
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                        <DataTableInputFilter column="email" label="Email" />
                        <DataTableFacetedFilter column="role" options={Object.values(UserRole).map(role => ({ data: role }))} />
                    </DataTableToolbar>
                </DataTable>
            </div>
        </div>
    )
}
export default Admin
