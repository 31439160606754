/* eslint-disable @typescript-eslint/no-explicit-any */
import { zodResolver } from '@hookform/resolvers/zod'
import { LoadingIcon } from 'components/loading-icon'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { useLogin } from 'hooks/use-login'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { cn } from 'utils/cn'
import { z } from 'zod'

const resetPasswordSchema = z.object({
    email: z
        .string()
        .email({
            message: 'Wrong email address',
        })
        .min(5),
})

const ResetPassword = () => {
    const { theme } = useAppSelector(state => state.theme)
    const [isSentInstruction, setIsSentInstruction] = useState<boolean>(false)
    const { isButtonDisabled, resetUserPassword } = useLogin()

    const navigate = useNavigate()

    const resetPasswordForm = useForm<z.infer<typeof resetPasswordSchema>>({
        resolver: zodResolver(resetPasswordSchema),
        defaultValues: {
            email: '',
        },
    })

    function onSubmit(values: z.infer<typeof resetPasswordSchema>) {
        resetUserPassword(values.email, () => setIsSentInstruction(true))
    }

    if (isSentInstruction) {
        return (
            <div className="flex h-screen w-screen flex-col items-center justify-center">
                <div
                    className={cn(
                        'flex flex-col gap-3 rounded-xl p-5 md:w-1/3',
                        theme === 'dark' ? 'border-[#0B1E55] bg-[#030E31]' : 'bg-slate-200',
                    )}
                >
                    <span className="text-4xl">
                        The instruction has been sent to
                        <br />
                        <span className="break-all font-bold">{resetPasswordForm.getValues().email}</span>
                    </span>
                    <Button onClick={() => navigate('/login')} className="mt-24 rounded-xl py-7 text-lg">
                        Back to login
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <div className="flex h-screen w-screen flex-col items-center justify-center">
            <div
                className={cn(
                    'flex flex-col gap-3 rounded-xl border-4 p-5 md:w-1/3',
                    theme === 'dark' ? 'border-[#0B1E55] bg-[#030E31]' : 'bg-slate-200',
                )}
            >
                <span className="text-4xl font-semibold">Reset password</span>
                <span className="text-lg">We'll email you instructions to reset your password</span>
                <Form {...resetPasswordForm}>
                    <form onSubmit={resetPasswordForm.handleSubmit(onSubmit)} className="flex w-full flex-col gap-5">
                        <FormField
                            control={resetPasswordForm.control}
                            name="email"
                            render={({ field }: { field: any }) => (
                                <FormItem>
                                    <FormLabel className="text-lg">Email</FormLabel>
                                    <FormControl>
                                        <Input className="" placeholder="email@doe.com" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <Button disabled={isButtonDisabled} type="submit" className="rounded-xl py-7 text-lg uppercase">
                            <LoadingIcon loading={isButtonDisabled} className="mr-2" />
                            Reset
                        </Button>
                        <Link to="/login" className="cursor-pointer py-1 text-center text-lg text-green-500">
                            Return to login
                        </Link>
                    </form>
                </Form>
            </div>
        </div>
    )
}
export default ResetPassword
