import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { RefreshButton } from 'components/refresh-button'
import { DataTable } from 'components/ui/data-table'
import { DataTableFacetedFilter } from 'components/ui/data-table-faceted-filter'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { emptyCounter } from 'config/constants.config'
import { ENV, dotenv } from 'config/dotenv.config'
import { toastConfig } from 'config/toasts.config'
import { urlConfig } from 'config/url.config'
import CreateFirstProject from 'pages/create-project/create-first-project'
import { DashboardCards } from 'pages/dashboard/components/dashboard-cards'
import { DashboardCharts } from 'pages/dashboard/components/dashboard-charts'
import { dashboardColumns } from 'pages/dashboard/components/dashboard-columns'
import { memo, useCallback } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import { useGetCounterQuery } from 'redux/api/counter-api'
import { useGetAllProjectsByOwnerIdQuery } from 'redux/api/project-api'
import { useAppSelector } from 'redux/store'
import { ProjectStatus } from 'types/schemas/project.schema'
import { cn } from 'utils/cn'

interface MainPageProps {}
export const MainPage: React.FC<MainPageProps> = memo(() => {
    const { theme } = useAppSelector(state => state.theme)

    const auth = useAuthUser()()

    const navigate = useNavigate()

    const {
        data: ownerProjects,
        isLoading: isLoadingOwnerProjects,
        refetch: refetchOwnerProjects,
    } = useGetAllProjectsByOwnerIdQuery(auth?.userOwnerId)
    // const { data: projectsData, isLoading: projectsIsLoading, refetch: projectsRefetch } = useGetProjectsQuery()
    const { data: counterData, isLoading: counterIsLoading, refetch: counterRefetch } = useGetCounterQuery()

    const onRefetch = useCallback(async () => {
        try {
            const res = await fetch(`${dotenv.get(ENV.API_URL)}${urlConfig.api.revalidate}`, {
                method: 'POST',
            })
            if (!res.ok) {
                throw new Error('Failed to refetch')
            }
            refetchOwnerProjects()
            counterRefetch()
            toastConfig.refetch.success()
        } catch (error) {
            console.error(error)
            toastConfig.refetch.error()
        }
    }, [counterRefetch, refetchOwnerProjects])

    if (counterIsLoading || isLoadingOwnerProjects) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <div className="flex w-full items-center justify-between">
                <Heading title="Dashboard" description="Manage your projects" />
                <RefreshButton onClick={onRefetch} />
            </div>

            <DashboardCards data={ownerProjects ?? []} className="flex gap-5" />

            {(ownerProjects?.length ?? 0) > 0 ? (
                <DataTable
                    columns={dashboardColumns}
                    data={ownerProjects?.map(project => ({ ...project, navigateFn: (url: string) => navigate(url) })) ?? []}
                    pagination={!!((ownerProjects?.length ?? 0) > 5)}
                    initialSorting={{
                        column: 'name',
                        direction: 'asc',
                    }}
                    initialHidden={['id']}
                >
                    <DataTableToolbar>
                        <DataTableInputFilter column="name" label="Name" />
                        <DataTableInputFilter column="url" label="URL" />
                        <DataTableFacetedFilter
                            column="status"
                            options={Object.values(ProjectStatus).map(status => ({ data: status }))}
                        />
                    </DataTableToolbar>
                </DataTable>
            ) : (
                <div className="flex items-center justify-center">
                    <div
                        className={cn(
                            'flex flex-col gap-3 rounded-xl border-2 p-5 lg:w-1/3',
                            theme === 'dark' ? 'border-[#0B1E55] bg-[#030E31]' : 'bg-slate-200',
                        )}
                    >
                        <h2 className="text-2xl font-semibold">Create your first project</h2>
                        <span>Add your first project</span>
                        <CreateFirstProject />
                    </div>
                </div>
            )}

            {auth?.role === 'superadmin' && (
                <DashboardCharts data={counterData ?? emptyCounter} className="grid w-full grid-cols-2 gap-5" />
            )}

            <div className="h-5" />
        </div>
    )
})
MainPage.displayName = 'MainPage'
