import { z } from 'zod'
import { LoginSchema } from './login.schema'

export enum UserRole {
    USER = 'user',
    ADMIN = 'admin',
    SUPERADMIN = 'superadmin',
}

export const UserSchema = z.object({
    id: z.string().nonempty(),
    role: z.nativeEnum(UserRole),
    name: z.string().nonempty(),
    userOwnerId: z.string().optional(),
    registered_ip: z.string().optional(),
    projects: z.number().optional(),

    ...LoginSchema.shape,
})

export type UserSchemaType = z.infer<typeof UserSchema>
export const NewUserSchema = UserSchema.omit({ id: true })

export type NewUserSchemaType = z.infer<typeof NewUserSchema>

export const AdminSchema = z.object({
    id: z.string().nonempty(),
    name: z.string().nonempty(),
    email: z.string().email().nonempty(),
    lastSignIn: z.string().nonempty(),
    registered_ip: z.string().nonempty(),
    useOwnerId: z.string().nonempty(),
    projects: z.number().optional(),
})

export type AdminSchemaType = z.infer<typeof AdminSchema>
