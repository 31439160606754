import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { toastConfig } from 'config/toasts.config'
import { useCallback, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useForm } from 'react-hook-form'
import { useCreateProjectMutation } from 'redux/api/project-api'
import { CreateProjectSchema, CreateProjectSchemaType } from 'types/schemas/project.schema'
import { formatUrl } from 'utils/format-url'

const CreateFirstProject = () => {
    const auth = useAuthUser()()
    const [createProject, createProjectRes] = useCreateProjectMutation()
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(false)

    const projectForm = useForm<CreateProjectSchemaType>({
        resolver: zodResolver(CreateProjectSchema),
        defaultValues: {
            name: '',
            url: '',
            email: '',
            interval: '',
        },
    })
    const onCreateSubmit = useCallback<(values: CreateProjectSchemaType) => Promise<void>>(
        async values => {
            try {
                setIsCreateButtonDisabled(true)

                const res = await createProject({
                    name: values.name,
                    url: formatUrl(values.url),
                    email: values.email,
                    interval: Number(values.interval),
                    ownerId: auth?.userOwnerId,
                }).unwrap()
                if (createProjectRes.isError) {
                    console.error(createProjectRes.error)
                    throw new Error()
                }

                toastConfig.project.create.success(values.name)
                projectForm.reset()
            } catch (error) {
                console.error(error)
                toastConfig.project.create.error(values.name)
            } finally {
                setIsCreateButtonDisabled(false)
            }
        },
        [auth?.userOwnerId, createProject, createProjectRes.error, createProjectRes.isError, projectForm],
    )

    return (
        <div>
            <Form {...projectForm}>
                <form onSubmit={projectForm.handleSubmit(onCreateSubmit)} className="space-y-3">
                    <FormField
                        control={projectForm.control}
                        name="name"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Name</FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type="text"
                                        name="name"
                                        placeholder="My Cool Project"
                                        required
                                        disabled={isCreateButtonDisabled}
                                        className="w-full"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={projectForm.control}
                        name="url"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>URL</FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type="text"
                                        name="url"
                                        placeholder="https://some-url.com"
                                        required
                                        disabled={isCreateButtonDisabled}
                                        className="w-full"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={projectForm.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type="text"
                                        name="email"
                                        placeholder="johndoe@email.com"
                                        required
                                        disabled={isCreateButtonDisabled}
                                        className="w-full"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={projectForm.control}
                        name="interval"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Check Interval</FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type="number"
                                        name="url"
                                        placeholder="10 minutes"
                                        required
                                        disabled={isCreateButtonDisabled}
                                        className="number-input-no-arrows w-full"
                                    />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit" disabled={isCreateButtonDisabled} className="w-full">
                        Create Project
                    </Button>
                </form>
            </Form>
        </div>
    )
}
export default CreateFirstProject
