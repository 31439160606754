import { FullPageLoader } from 'components/full-page-loader'
import { Heading } from 'components/heading'
import { RefreshButton } from 'components/refresh-button'
import { DataTable } from 'components/ui/data-table'
import { DataTableInputFilter } from 'components/ui/data-table-input-filter'
import { DataTableToolbar } from 'components/ui/data-table-toolbar'
import { toastConfig } from 'config/toasts.config'
import { projectLogsColumns } from 'pages/project-logs/components/project-logs-columns'
import { memo, useCallback } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useGetProjectLogsByOwnerIdQuery } from 'redux/api/project-log-api'

interface ProjectLogsPageProps {}
export const ProjectLogsPage: React.FC<ProjectLogsPageProps> = memo(() => {
    const auth = useAuthUser()()

    const {
        data: projectsLogs,
        isLoading: isLoadingLogs,
        refetch: refetchLogs,
    } = useGetProjectLogsByOwnerIdQuery(auth?.userOwnerId)

    const onRefetch = useCallback(() => {
        try {
            refetchLogs()
            toastConfig.refetch.success()
        } catch (error) {
            console.error(error)
            toastConfig.refetch.error()
        }
    }, [refetchLogs])

    if (isLoadingLogs) {
        return <FullPageLoader />
    }

    return (
        <div className="animate-in fade-in-0 container min-h-screen space-y-5 pt-20 transition-all duration-300">
            <div className="flex w-full items-center justify-between">
                <Heading title="Project Logs" description="Manage your projects" />
                <RefreshButton onClick={onRefetch} />
            </div>

            <DataTable
                columns={projectLogsColumns}
                data={projectsLogs ?? []}
                pagination
                initialSorting={{
                    column: 'date',
                    direction: 'desc',
                }}
                initialHidden={['id', 'projectId']}
            >
                <DataTableToolbar>
                    <DataTableInputFilter column="projectName" label="Project Name" />
                    <DataTableInputFilter column="projectUrl" label="Project URl" />
                    <DataTableInputFilter column="projectEmail" label="Project Email" />
                </DataTableToolbar>
            </DataTable>

            <div className="h-5" />
        </div>
    )
})
ProjectLogsPage.displayName = 'ProjectLogsPage'
