import { zodResolver } from '@hookform/resolvers/zod'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from 'components/ui/form'
import { Input } from 'components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { toast } from 'components/ui/use-toast'
import React, { forwardRef, memo, useCallback, useEffect, useState } from 'react'
import { useAuthUser } from 'react-auth-kit'
import { useForm } from 'react-hook-form'
import { useDeleteAdminUserMutation, useUpdateAdminUserMutation } from 'redux/slices/user-api'
import { UserRole } from 'types/schemas/user.schema'
import { cn } from 'utils/cn'
import { z } from 'zod'
import { AdminSchemaType } from '../admin.schema'
import { useGetProjectByIdQuery, useUpdateProjectMutation } from 'redux/api/project-api'
import { UpdateProjectSchema, UpdateProjectSchemaType } from 'types/schemas/project.schema'
import { formatUrl } from 'utils/format-url'
import { toastConfig } from 'config/toasts.config'
import { setIsUpdateProjectOpenAction } from 'redux/slices/update-project.slice'
import { useAppDispatch } from 'redux/store'

const updateSchema = z.object({
    email: z.string().min(2).email(),
    name: z.string().min(2),
    password: z.string().optional(),
    role: z.nativeEnum(UserRole),
})

interface AdminActionsCellProps extends React.ComponentProps<typeof Button> {
    id: UpdateProjectSchemaType['id']
    email: UpdateProjectSchemaType['email']
    url: UpdateProjectSchemaType['url']
    interval: number
    name: UpdateProjectSchemaType['name']
    refetch?: () => void
}
export const UpdateActionsCell = memo(
    forwardRef<React.ComponentRef<typeof Button>, AdminActionsCellProps>(
        ({ id, className, email, name, url, interval, ...props }, ref) => {
            const auth = useAuthUser()()
            const [deleteUser, deleteUserRes] = useDeleteAdminUserMutation()
            const [updateUser, updateUserRes] = useUpdateAdminUserMutation()
            const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState<boolean>(false)
            const [updateProject, updateProjectRes] = useUpdateProjectMutation()
            const dispatch = useAppDispatch()
            const { data: project, isLoading: isLoadingProject, refetch: refetchProject } = useGetProjectByIdQuery(id as string)

            const projectUpdateForm = useForm<UpdateProjectSchemaType>({
                resolver: zodResolver(UpdateProjectSchema),
                defaultValues: {
                    id: '',
                    name: '',
                    url: '',
                    email: '',
                    interval: '',
                },
            })

            const onUpdateSubmit = useCallback<(values: UpdateProjectSchemaType) => Promise<void>>(
                async values => {
                    try {
                        setIsCreateButtonDisabled(true)

                        const res = await updateProject({
                            id: id as string,
                            name: values.name,
                            url: formatUrl(values.url),
                            email: values.email,
                            interval: +values.interval,
                        }).unwrap()
                        if (updateProjectRes.isError) {
                            console.error(updateProjectRes.error)
                            throw new Error()
                        }

                        toastConfig.project.create.success(values.name)
                        projectUpdateForm.reset()
                        dispatch(setIsUpdateProjectOpenAction(false))
                    } catch (error) {
                        console.error(error)
                        toastConfig.project.create.error(values.name)
                    } finally {
                        setIsCreateButtonDisabled(false)
                    }
                },
                [dispatch, id, projectUpdateForm, updateProject, updateProjectRes.error, updateProjectRes.isError],
            )

            useEffect(() => {
                projectUpdateForm.setValue('id', id)
                projectUpdateForm.setValue('email', email)
                projectUpdateForm.setValue('name', name)
                projectUpdateForm.setValue('url', url)
                projectUpdateForm.setValue('interval', String(interval))
            }, [email, id, interval, name, project, projectUpdateForm, url])

            return (
                <div className="flex items-center gap-2">
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <Button ref={ref} variant="outline" size="sm" {...props} className="capitalize">
                                Update
                            </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>You can update user email, name and password</AlertDialogTitle>
                            </AlertDialogHeader>
                            <Form {...projectUpdateForm}>
                                <form onSubmit={projectUpdateForm.handleSubmit(onUpdateSubmit)} className="flex flex-col gap-2">
                                    <FormField
                                        control={projectUpdateForm.control}
                                        name="name"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>Name</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="someemail@example.com" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={projectUpdateForm.control}
                                        name="url"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>URL</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="someemail@example.com" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={projectUpdateForm.control}
                                        name="email"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>EMAIL</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="John Doe" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={projectUpdateForm.control}
                                        name="interval"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>INTERVAL</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="*****" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />

                                    <div className="flex justify-end gap-2">
                                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                                        <AlertDialogAction type="submit">Submit</AlertDialogAction>
                                    </div>
                                </form>
                            </Form>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            )
        },
    ),
)
UpdateActionsCell.displayName = 'ActionCell'
