import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface UpdateProjectSliceState {
    isOpen: boolean
}

const initialState: UpdateProjectSliceState = {
    isOpen: false,
}

export const updateProjectSlice = createSlice({
    name: 'updateProject',
    initialState,
    reducers: {
        setIsUpdateProjectOpenAction: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload
        },
    },
})

export const { setIsUpdateProjectOpenAction } = updateProjectSlice.actions
