import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query/react'
import { useDispatch, useSelector, type TypedUseSelectorHook } from 'react-redux'
import { appLogApi } from 'redux/api/app-logs-api'
import { counterApi } from 'redux/api/counter-api'
import { projectApi } from 'redux/api/project-api'
import { projectLogsApi } from 'redux/api/project-log-api'
import { commandSlice } from 'redux/slices/command.slice'
import { createProjectSlice } from 'redux/slices/create-project.slice'
import { themeSlice } from 'redux/slices/theme.slice'
import { userApi } from './slices/user-api'
import { updateProjectSlice } from './slices/update-project.slice'

type RootState = ReturnType<typeof rootReducer>
type AppStore = ReturnType<typeof configureStore>
type AppDispatch = AppStore['dispatch']

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const rootReducer = combineReducers({
    [themeSlice.name]: themeSlice.reducer,
    [commandSlice.name]: commandSlice.reducer,
    [createProjectSlice.name]: createProjectSlice.reducer,
    [updateProjectSlice.name]: updateProjectSlice.reducer,
    //
    [projectApi.reducerPath]: projectApi.reducer,
    [projectLogsApi.reducerPath]: projectLogsApi.reducer,
    [counterApi.reducerPath]: counterApi.reducer,
    [appLogApi.reducerPath]: appLogApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
})

export const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        })
            .concat(projectApi.middleware)
            .concat(projectLogsApi.middleware)
            .concat(counterApi.middleware)
            .concat(appLogApi.middleware)
            .concat(userApi.middleware),
})

setupListeners(store.dispatch)
