import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { revalidateSeconds, rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { ProjectSchemaType, UpdateProjectSchemaPatchType, UpdateProjectSchemaType } from 'types/schemas/project.schema'

interface ServerResponse {
    message: string
}

export const projectApi = createApi({
    reducerPath: 'projectApi',
    tagTypes: ['Project'],
    keepUnusedDataFor: revalidateSeconds,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getProjects: builder.query<ProjectSchemaType[], void>({
            query: () => urlConfig.api.project,
            providesTags: ['Project'],
        }),
        getAllProjectsByOwnerId: builder.query<ProjectSchemaType[], ProjectSchemaType['ownerId']>({
            query: id => ({
                url: `${urlConfig.api.project}/owner/${id}`,
                method: 'GET',
            }),
            providesTags: ['Project'],
        }),
        getProjectById: builder.query<ProjectSchemaType, ProjectSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.project}/${id}`,
                method: 'GET',
            }),
            providesTags: ['Project'],
        }),
        createProject: builder.mutation<
            ServerResponse,
            Pick<ProjectSchemaType, 'name' | 'url' | 'email' | 'interval' | 'ownerId'>
        >({
            query: project => ({
                url: urlConfig.api.project,
                method: 'POST',
                body: project,
            }),
            invalidatesTags: ['Project'],
        }),
        updateProject: builder.mutation<ServerResponse, UpdateProjectSchemaPatchType>({
            query: project => ({
                url: urlConfig.api.project,
                method: 'PATCH',
                body: project,
            }),
            invalidatesTags: ['Project'],
        }),
        deleteProject: builder.mutation<ServerResponse, ProjectSchemaType['id']>({
            query: id => ({
                url: `${urlConfig.api.project}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Project'],
        }),
    }),
})

export const {
    useGetProjectsQuery,
    useGetAllProjectsByOwnerIdQuery,
    useGetProjectByIdQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} = projectApi
