import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { rtkBaseQuery } from 'config/rtkQuery.config'
import { AdminSchemaType, UserRole, UserSchemaType } from 'types/schemas/user.schema'

type UpdateUserAdminSchema = {
    id: string
    email?: string
    name?: string
    password?: string
    role?: UserRole
}

export const userApi = createApi({
    reducerPath: 'userApi',
    tagTypes: ['User'],
    // refetchOnFocus: true,
    // refetchOnReconnect: true,
    // refetchOnMountOrArgChange: true,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getAllUsers: builder.query<UserSchemaType[], void>({
            query: () => ({
                url: '/user',
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        getAllAdminUsers: builder.query<AdminSchemaType[], AdminSchemaType['id']>({
            query: id => ({
                url: `/user/admin/${id}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        createUser: builder.mutation<void, Omit<UserSchemaType, 'id'>>({
            query: admin => ({
                url: '/user',
                method: 'POST',
                body: admin,
            }),
            invalidatesTags: ['User'],
        }),
        deleteUser: builder.mutation<void, string>({
            query: id => ({
                url: `/user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
        deleteAdminUser: builder.mutation<void, string>({
            query: id => ({
                url: `/user/admin/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User'],
        }),
        updateAdminUser: builder.mutation<void, UpdateUserAdminSchema>({
            query: admin => ({
                url: `/user/admin/${admin.id}`,
                method: 'PATCH',
                body: admin,
            }),
            invalidatesTags: ['User'],
        }),
    }),
})

export const {
    useGetAllUsersQuery,
    useGetAllAdminUsersQuery,
    useCreateUserMutation,
    useDeleteUserMutation,
    useDeleteAdminUserMutation,
    useUpdateAdminUserMutation,
} = userApi
