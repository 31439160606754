import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { revalidateSeconds, rtkBaseQuery } from 'config/rtkQuery.config'
import { urlConfig } from 'config/url.config'
import { ProjectLogType } from 'types/project-log.type'

export const projectLogsApi = createApi({
    reducerPath: 'projectLogsApi',
    tagTypes: ['ProjectLog', 'ProjectsLog'],
    keepUnusedDataFor: revalidateSeconds,
    baseQuery: rtkBaseQuery,
    endpoints: builder => ({
        getProjectLogs: builder.query<ProjectLogType[], void>({
            query: () => urlConfig.api.projectLogs,
            providesTags: ['ProjectsLog'],
        }),
        getProjectLogsByOwnerId: builder.query<ProjectLogType[], string>({
            query: id => urlConfig.api.projectLogs + `/${id}`,
            providesTags: ['ProjectsLog'],
        }),
        getProjectLogsByProjectId: builder.query<ProjectLogType[], string>({
            query: id => urlConfig.api.projectLogs + `/${id}/project`,
            providesTags: ['ProjectLog'],
        }),
    }),
})

export const { useGetProjectLogsQuery, useGetProjectLogsByOwnerIdQuery, useGetProjectLogsByProjectIdQuery } = projectLogsApi
