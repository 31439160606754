import { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from 'components/ui/data-table-column-header'
import { AdminSchemaType } from './admin.schema'
import { AdminActionsCell } from './cells/admin-actions'

export const adminUsersColumns: ColumnDef<AdminSchemaType>[] = [
    {
        accessorKey: 'id',
        header: 'ID',
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
        cell: ({ row }) => <span>{row.getValue('name')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'email',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Email" />,
        cell: ({ row }) => <span>{row.getValue('email')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'projects',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Projects" />,
        cell: ({ row }) => <span>{row.getValue('projects')}</span>,
    },
    {
        accessorKey: 'role',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Role" />,
        cell: ({ row }) => <span className="capitalize">{row.getValue('role')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'lastSignIn',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Last Sign In" />,
        cell: ({ row }) => <span>{row.getValue('lastSignIn')}</span>,
        sortingFn: 'text',
        filterFn: 'includesString',
    },
    {
        accessorKey: 'registered_ip',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Register ip" />,
        cell: ({ row }) => <span>{row.getValue('registered_ip')}</span>,
        
    },
    {
        accessorKey: 'status',
        header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
        cell: ({ row }) => (
            <AdminActionsCell
                id={row.getValue('id')}
                email={row.getValue('email')}
                name={row.getValue('name')}
                role={row.getValue('role')}
                refetch={row.original.refetch}
            />
        ),
        sortingFn: 'text',
        filterFn: 'weakEquals',
    },
]
